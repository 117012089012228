import Image from "next/image"
import { SearchInput } from "./SearchInput"
import { Navigation } from "./Navigation"
import { Atendimento } from "./Atendimento"
import { LinksHeader } from "./LinksHeader"
import { NavigationMobile } from "./NavigationMobile"
import { useMediaQuery } from "@chakra-ui/react"
import dynamic from "next/dynamic"
import Link from "next/link"
import { useEffect, useState } from "react"

const FreteGratis = dynamic(() => import("../FreteGratis").then((mod) => mod.FreteGratis))
const Cart = dynamic(() => import("./Cart").then((mod) => mod.Cart))

function Header({ subcategorias, segmentos, frete = true }) {
  const [isMobile] = useMediaQuery("(max-width: 768px)")

  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    setMobile(isMobile)
  }, [isMobile])

  const menuLinks = {
    segmentos: segmentos?.map((segmento) => ({
      name: segmento.descricao,
      url_site: segmento.link_segmento,
      cod_grupo: segmento.codigo_segmento,
      link: segmento.link_segmento,
    })),
    subcategorias: subcategorias?.map((subcategoria) => ({
      name: subcategoria.name,
      url_site: subcategoria.url_site,
      cod_grupo: subcategoria.codigo_grupo_produto,
      link: `/categoria/${subcategoria.url_site}/${subcategoria.cod_grupo}`,
    })),
  }

  return (
    <>
      <LinksHeader />

      <header className="bg-white shadow-sm pb-5 pt-2 items-center flex-col hidden md:flex">
        <div className="flex container mx-auto items-center justify-between flex-1">
          <Link href="/">
            <Image
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/logobanner.png"
              width={130}
              height={50}
              objectFit="contain"
              alt="Logo innovation brindes"
              className="cursor-pointer"
            />
          </Link>

          <Navigation menuLinks={menuLinks} />

          <SearchInput />

          <div className="flex items-center gap-2">
            <Atendimento />
            <Cart />
          </div>
        </div>
      </header>

      {mobile && <NavigationMobile menuLinks={menuLinks} />}

      {mobile && (
        <div className="md:hidden flex items-center justify-center py-2 w-full">
          <SearchInput />
        </div>
      )}
      {frete && <FreteGratis />}
    </>
  )
}

export default Header
