import Link from "next/link"
import { MdKeyboardArrowDown } from "react-icons/md"

export function Navigation({ menuLinks }) {
  return (
    <nav className="flex items-center gap-7">
      <button
        aria-label="Brindes"
        className="flex text-xs uppercase font-bold relative items-center group hover:text-[#F45100]"
      >
        Brindes
        <MdKeyboardArrowDown className="text-xl" />
        <MenuList list={menuLinks?.subcategorias} />
      </button>

      <button
        aria-label="Segmentos"
        className="flex text-xs uppercase font-bold relative items-center group hover:text-[#F45100]"
      >
        Segmentos
        <MdKeyboardArrowDown className="text-xl" />
        <MenuList list={menuLinks?.segmentos} />
      </button>
      <Link href="/segmento/novidades/6" passHref>
        <button aria-label="Lançamentos" className="text-xs uppercase font-bold hover:text-[#F45100]">
          Lançamentos
        </button>
      </Link>
      <Link href="/ultra-rapido" passHref>
        <button aria-label="Pronto em 48 horas" className="text-xs uppercase font-bold hover:text-[#F45100]">
          Pronto em 48 horas
        </button>
      </Link>
    </nav>
  )
}

function MenuList({ list }) {
  return (
    <ul className="hidden border overflow-hidden group-hover:block absolute z-10 top-full left-0 bg-white shadow-lg columns-4">
      {list.map((item, index) => (
        <li
          key={index}
          className="text-black py-2 px-4 hover:bg-[#F9F9F9]  w-full text-start animate-fade-in-left font-normal relative before:w-0 hover:before:w-2 before:h-full before:absolute before:top-0 before:left-0 before:bg-[#80BC00]"
        >
          <a href={item.link} className="w-[200px] block">
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  )
}
