import Image from "next/image"
import { useState } from "react"
import { IoIosCloseCircle } from "react-icons/io"
import { GiHamburgerMenu } from "react-icons/gi"
import { MdGridView } from "react-icons/md"
import Link from "next/link"

import { Atendimento } from "./Atendimento"
import dynamic from "next/dynamic"

const Cart = dynamic(() => import("./Cart").then((mod) => mod.Cart))

export function NavigationMobile({ menuLinks }) {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <nav className="flex w-full justify-between px-2">
      <button className="text-2xl" onClick={() => setIsOpenMenu(!isOpenMenu)}>
        <GiHamburgerMenu className="text-2xl" />
      </button>
      <Link href="/">
        <Image
          src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/logo%20mobile-01.svg"
          width={40}
          height={38}
          objectFit="contain"
          alt="Logo innovation brindes"
        />
      </Link>
      <Atendimento />

      <Cart />

      {isOpenMenu && (
        <div className="gap-2 flex flex-col fixed animate-fade-in-left inset-0 bg-white z-[9999]">
          <button className="text-2xl" onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <IoIosCloseCircle className="text-2xl absolute top-2 right-2" onClick={() => setIsOpenMenu(!isOpenMenu)} />
          </button>
          <div className="flex items-center gap-2 border-b  p-2">
            <Image
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/logo%20mobile-01.svg"
              width={130}
              height={50}
              objectFit="contain"
              alt="Logo innovation brindes"
            />
            <span className="text-lg uppercase font-bold text-center">Catálogo de Brindes</span>
          </div>

          <div className="flex items-center justify-center gap-2">
            <Link href="/segmento/novidades/6">
              <span className="text-lg uppercase font-medium text-center text-[#F45100] cursor-pointer">
                Lançamentos
              </span>
            </Link>
            <div className="w-px h-5 bg-[#000000]" />
            <Link href="/ultra-rapido">
              <span className="text-lg uppercase font-medium text-center text-[#F45100] cursor-pointer">
                Pronto em 48 horas
              </span>
            </Link>
          </div>

          <ul className="overflow-auto">
            {menuLinks?.subcategorias.map((item, index) => (
              <li key={index} className="p-2 hover:bg-[#F9F9F9] cursor-pointer">
                <Link passHref href={item.link}>
                  <a href={item.link} className="flex items-center gap-2 flex-1 w-full">
                    <MdGridView className="text-xl" />
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  )
}
