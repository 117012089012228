import { FaPhoneAlt } from "react-icons/fa"
import { useWhatsApp } from "../../contexts/WhatsAppProvider"

export function Atendimento() {
  const { setOpenChat } = useWhatsApp()

  return (
    <div className="flex items-center gap-2 cursor-pointer" onClick={() => setOpenChat(true)}>
      <FaPhoneAlt className="text-[#3D3D3D] text-lg" />
      <div className="flex flex-col leading-4 text-xs">
        <span>CENTRAL DE ATENDIMENTO</span>
        <span className="font-semibold">11 2649-6030</span>
      </div>
    </div>
  )
}
