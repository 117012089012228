import { useState } from "react"
import { MdOutlineSearch } from "react-icons/md"
import { useQuery } from "react-query"
import { api } from "../../services/api"
import { useDebounce } from "../../hooks/useDebounce"
import Link from "next/link"
import { useRouter } from "next/router"

async function getSuggestionsSearch(search) {
  const response = await api.get(`/site/v2/pedido/busca-palavra-chave/${search}`)

  return response.data
}

export function SearchInput() {
  const [inputSuggestions, setInputSuggestions] = useState("")
  const debouncedValue = useDebounce(inputSuggestions, 200)

  const router = useRouter()

  const { data: suggestions } = useQuery({
    queryKey: ["search", debouncedValue],
    queryFn: () => getSuggestionsSearch(inputSuggestions),
    enabled: !!debouncedValue,
    staleTime: 1000 * 60 * 60 * 24,
  })

  function onDebounceInput(event) {
    setInputSuggestions(event.target.value)
  }

  function onSearch(event) {
    event.preventDefault()

    router.push(`/search/${inputSuggestions}`)
  }

  return (
    <div className="relative max-w-xs w-full group">
      <form
        onSubmit={onSearch}
        className="relative max-w-xs px-4 py-1 w-full border border-[#000000] rounded-md flex items-center focus-within:border-[#80BC00] focus-within:shadow-lg overflow-hidden"
      >
        <input
          id="search"
          name="search"
          type="text"
          className="flex-1 text-lg rounded-md placeholder:text-[#000000] bg-transparent outline-none"
          placeholder="Pesquise"
          value={inputSuggestions}
          onChange={onDebounceInput}
        />

        <label htmlFor="search" className="cursor-pointer flex items-center justify-center">
          <button type="submit">
            <MdOutlineSearch className="text-2xl" />
          </button>
        </label>
      </form>

      {suggestions && (
        <ul className="hidden group-hover:block absolute z-10 top-full left-0 w-full bg-white text-gray-900 shadow-md overflow-hidden">
          {suggestions.map((suggestion, index) => (
            <li key={index} className="p-2 hover:bg-[#F9F9F9] cursor-pointer animate-fade-in-left">
              <Link href={suggestion.link_categoria}>{suggestion.descricao_categoria}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
