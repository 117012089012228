import React from "react"
import Link from "next/link"

export const LinksHeader = () => {
  return (
    <div className="flex container items-center flex-1 mx-auto justify-end gap-2 px-2 md:px-0 border-b md:border-none py-2 mb-2 md:py-0 md:mb-0">
      <Link href="/trabalhe-conosco">
        <span className="text-xs text-[#414042] cursor-pointer">Seja um Consultor</span>
      </Link>
      <div className="w-px h-3 bg-[#414042]" />
      <Link href="/acompanhe-seu-pedido">
        <span className="text-xs text-[#414042] cursor-pointer">Status do seu pedido</span>
      </Link>
      <div className="w-px h-3 bg-[#414042]" />

      <a href="https://blog.innovationbrindes.com.br" className="text-xs text-[#414042] cursor-pointer">
        Blog
      </a>
    </div>
  )
}
